import React from 'react';
import { List } from '@mui/material';
import { AccountTree, Home, FindInPage, School, Star, Person } from '@mui/icons-material';
import { PortalNavMenuGroup, PortalNavMenuItem } from './components';

const sourcegraphUrl = process.env.NX_SOURCEGRAPH_URL;
const starMeUpUrl = process.env.NX_STARMEUP_URL;
const smartConnectUrl = process.env.NX_SMARTCONNECT_URL;
const confluenceLearningUrl = `${process.env.NX_CONFLUENCE_URL}/learning`;

const PortalNavMenu = () => (
  <List disablePadding dense>
    <PortalNavMenuItem Icon={Home} label="Home" to="/" iconurl="/assets/images/HomeIcon.png" />
    <PortalNavMenuItem Icon={AccountTree} label="Projects" to="/projects" iconurl="/assets/images/Projects.png" />
    <PortalNavMenuItem
      Icon={FindInPage}
      label="Search & Reuse code"
      href={sourcegraphUrl}
      target="_blank"
      rel="noopener noreferrer"
      iconurl="/assets/images/search.png"
    />
    <PortalNavMenuItem
      // Icon={School}
      label="Knowledge Management"
      href="http://atlassian.carcgl.com/confluence/display/HOWTO/Standard+Works+List"
      target="_blank"
      rel="noopener noreferrer"
      iconurl="/assets/images/BellIcon.png"
    />
    {/* <PortalNavMenuItem Icon={Star} label="Say Thanks!" href={starMeUpUrl} target="_blank" rel="noopener noreferrer" /> */}
    <PortalNavMenuItem
      // Icon={Person}
      label="Ask for help"
      href={smartConnectUrl}
      target="_blank"
      rel="noopener noreferrer"
      iconurl="/assets/images/faviconCarrierjflksdaj.ico"
    />
  </List>
);
export default PortalNavMenu;
