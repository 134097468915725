import React from 'react';
import { Grid } from '@mui/material';
import { AppPage } from '@inner-source/cis-shared/components';

import {
  TopActiveRepos,
  ProjectsToContribute,
  Leaderboard,
  HowTo,
  Learning,
  News,
  Metrics,
  SourceReposToDisplay,
  ActiveProjectsToDisplay,
} from './components';
import SourcegraphComponent from './components/SourcegraphComponent';
import {
  Card,
  CardHeader,
  Divider,
  Avatar,
  CardContent,
  CardActions,
  Button,
  Typography,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  Box,
  Snackbar,
  Slide,
  TableRow,
  TableCell,
  Tooltip,
  Link,
} from '@mui/material';

const sourcegraphUrl = process.env.NX_SOURCEGRAPH_URL;
const starMeUpUrl = process.env.NX_STARMEUP_URL;
const smartConnectUrl = process.env.NX_SMARTCONNECT_URL;
const confluenceLearningUrl = `${process.env.NX_CONFLUENCE_URL}/learning`;

const Home = () => (
  <AppPage>
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <img src="/assets/images/Image Banner Group_1.png" style={{ width: '100%' }}></img>
      </Grid>
      <Grid item xs={12} md={6}>
        <SourceReposToDisplay />
      </Grid>
      <Grid item xs={12} md={6}>
        <ActiveProjectsToDisplay />
      </Grid>

      {/* <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
        <ProjectsToContribute />
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
        <TopActiveRepos />
      </Grid>*/}
      <Grid item xs={12} md={4} sx={{ display: 'flex', padding: '5px' }}>
        <Card
          variant="outlined"
          sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', height: '270px', borderRadius: '16px' }}
        >
          <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'hidden' }}>
            <div style={{ textAlign: 'center', marginTop: '10px', height: '60px' }}>
              <p>
                <img style={{ verticalAlign: 'text-bottom' }} src="/assets/images/sourcegraph.png" alt="SourceGraph" />
              </p>
            </div>
            <div style={{ textAlign: 'center', verticalAlign: 'top', height: '50px' }}>
              <p>
                <h3 style={{ verticalAlign: 'text-top' }}>Sourcegraph</h3>
              </p>
            </div>
            <div style={{ fontSize: '12px', padding: '10px', height: 'calc(100% - 150px)' }}>
              Code search engine where all the Carrier reusable code is present.
            </div>
            <div style={{ textAlign: 'center', marginTop: '10px', height: '40px' }}>
              <Button variant="contained" href={sourcegraphUrl} target="_blank" style={{ width: '145px' }}>
                Code Search
              </Button>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4} sx={{ display: 'flex', padding: '5px' }}>
        <Card
          variant="outlined"
          sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', height: '270px', borderRadius: '16px' }}
        >
          <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'hidden' }}>
            <div style={{ textAlign: 'center', marginTop: '10px', height: '60px' }}>
              <p>
                <img
                  style={{ verticalAlign: 'text-bottom' }}
                  src="/assets/images/faviconCarrierjflksdaj.ico"
                  alt="smartConnect"
                />
              </p>
            </div>
            <div style={{ textAlign: 'center', verticalAlign: 'top', height: '50px' }}>
              <p>
                <h3 style={{ verticalAlign: 'text-top' }}>Smart Connect</h3>
              </p>
            </div>
            <div style={{ fontSize: '12px', padding: '10px', height: 'calc(100% - 150px)' }}>
              A collaboration platform for the Carrier employees to interact and ask questions and get answers from
              experts.
            </div>
            <div style={{ textAlign: 'center', marginTop: '10px', height: '40px' }}>
              <Button variant="contained" href={smartConnectUrl} target="_blank" style={{ width: '145px' }}>
                Ask Questions
              </Button>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4} sx={{ display: 'flex', padding: '5px' }}>
        <Card
          variant="outlined"
          sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', height: '270px', borderRadius: '16px' }}
        >
          <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'hidden' }}>
            <div style={{ textAlign: 'center', marginTop: '10px', height: '60px' }}>
              <p>
                <img style={{ verticalAlign: 'text-bottom' }} src="/assets/images/confluence.png" alt="confluence" />
              </p>
            </div>
            <div style={{ textAlign: 'center', verticalAlign: 'top', height: '50px' }}>
              <p>
                <h3 style={{ verticalAlign: 'text-top' }}>Knowledge Management</h3>
              </p>
            </div>
            <div style={{ fontSize: '12px', padding: '10px', height: 'calc(100% - 150px)' }}>
              A place to look for any technical articles, standards, framework and guidelines.
            </div>
            <div style={{ textAlign: 'center', marginTop: '5px', height: '40px' }}>
              <Button
                variant="contained"
                href="http://atlassian.carcgl.com/confluence/display/HOWTO"
                target="_blank"
                style={{ width: '145px' }}
              >
                Explore
              </Button>
            </div>
          </CardContent>
        </Card>
      </Grid>

      {/* Last row */}
      <Grid item xs={12} md={3} sx={{ display: 'flex', padding: '5px' }}>
        <Card
          variant="outlined"
          sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', height: '160px', borderRadius: '16px' }}
        >
          <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
            <div style={{ width: '100%', height: '100%' }}>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '10px',
                  float: 'left',
                  height: '100%',
                  width: 'calc(100% - 70px)',
                }}
              >
                <div style={{ textAlign: 'center', verticalAlign: 'top' }}>
                  <h2>150 Repositories</h2>
                </div>
                <div style={{ fontSize: '12px' }}>Code Repositories on Sourcegraph.</div>
              </div>
              <div style={{ textAlign: 'center', marginTop: '10px', float: 'right', height: '100%', width: '70px' }}>
                <img src="/assets/images/Iconawesome-file-code.png" alt="Projects" style={{ marginTop: '20px' }} />
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3} sx={{ display: 'flex', padding: '5px' }}>
        <Card
          variant="outlined"
          sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', height: '160px', borderRadius: '16px' }}
        >
          <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
            <div style={{ width: '100%', height: '100%' }}>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '10px',
                  float: 'left',
                  height: '100%',
                  width: 'calc(100% - 70px)',
                }}
              >
                <div style={{ textAlign: 'center', verticalAlign: 'top' }}>
                  <h2>19 Technologies</h2>
                </div>
                <div style={{ fontSize: '12px' }}>Technologies on Sourcegraph.</div>
              </div>
              <div style={{ textAlign: 'center', marginTop: '10px', float: 'right', height: '100%', width: '70px' }}>
                <img src="/assets/images/Iconawesome-codepen.png" alt="Projects" style={{ marginTop: '20px' }} />
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3} sx={{ display: 'flex', padding: '5px' }}>
        <Card
          variant="outlined"
          sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', height: '160px', borderRadius: '16px' }}
        >
          <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
            <div style={{ width: '100%', height: '100%' }}>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '10px',
                  float: 'left',
                  height: '100%',
                  width: 'calc(100% - 90px)',
                }}
              >
                <div style={{ textAlign: 'center', verticalAlign: 'top' }}>
                  <h2>175 Articles</h2>
                </div>
                <div style={{ fontSize: '12px' }}>Tech articles on Smart Connect.</div>
              </div>
              <div style={{ textAlign: 'center', marginTop: '10px', float: 'right', height: '100%', width: '85px' }}>
                <img src="/assets/images/plugins.png" alt="Projects" style={{ marginTop: '20px' }} />
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3} sx={{ display: 'flex', padding: '5px' }}>
        <Card
          variant="outlined"
          sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', height: '160px', borderRadius: '16px' }}
        >
          <CardContent sx={{ pt: 0, display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
            <Link href="mailto:admin.innersource@carrier.com" underline="hover">
              <div style={{ width: '100%', height: '100%' }}>
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '10px',
                    float: 'left',
                    height: '100%',
                    width: 'calc(100% - 90px)',
                  }}
                >
                  <h2>Keep Contribute More...</h2>
                </div>
                <div style={{ textAlign: 'center', marginTop: '10px', float: 'right', height: '100%', width: '85px' }}>
                  <img src="/assets/images/knowmore.png" alt="Projects" style={{ marginTop: '20px' }} />
                </div>
              </div>
            </Link>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </AppPage>
);

export default Home;
