import React, { useCallback } from 'react';
import { AccountTree, Home, FindInPage, School, Star, Person } from '@mui/icons-material';
import { IconButton, Tooltip, Link as ExternalLink } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link } from '@mui/material';

import { AppHeader, AppUserMenu } from '@inner-source/cis-shared/components';
import { useEnv } from '@inner-source/cis-shared/hooks';

const sourcegraphUrl = process.env.NX_SOURCEGRAPH_URL;
const starMeUpUrl = process.env.NX_STARMEUP_URL;
const smartConnectUrl = process.env.NX_SMARTCONNECT_URL;
const confluenceLearningUrl = `${process.env.NX_CONFLUENCE_URL}/learning`;

const PortalHeader = () => {
  const { user, setCurrentUser } = useEnv();
  const { oktaAuth } = useOktaAuth();

  const handleOnCloseSession = useCallback(async () => {
    await oktaAuth.signOut();
    setCurrentUser(null);
  }, [oktaAuth, setCurrentUser]);

  return (
    <AppHeader>
      {/* <Tooltip title="Home" arrow>
        <IconButton color="inherit" component={Link} to="/">
          <Home />
        </IconButton>
      </Tooltip>
      <Tooltip title="Projects" arrow>
        <IconButton color="inherit" component={Link} to="/projects">
          <AccountTree />
        </IconButton>
      </Tooltip>
      <Tooltip title="Search & reuse code" arrow>
        <IconButton
          color="inherit"
          component={ExternalLink}
          href={sourcegraphUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FindInPage />
        </IconButton>
      </Tooltip>
      <Tooltip title="Learning center" arrow>
        <IconButton
          color="inherit"
          component={ExternalLink}
          href={confluenceLearningUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <School />
        </IconButton>
      </Tooltip>
      {/* <Tooltip title="Say thanks!" arrow>
        <IconButton
          color="inherit"
          component={ExternalLink}
          href={starMeUpUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Star />
        </IconButton>
      </Tooltip> 
      <Tooltip title="Ask for help" arrow>
        <IconButton
          color="inherit"
          component={ExternalLink}
          href={smartConnectUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Person />
        </IconButton>
      </Tooltip> 
      <AppNavHeader title="Innersource" onClose={toggleMenu}/>*/}
      <Link underline="none" component={RouterLink} to="/" color="inherit">
        <div>
          <div style={{ float: 'left' }}>
            <img src="/assets/images/Carrier_Logo.png"></img>
          </div>
          <div style={{ float: 'right', marginLeft: '10px' }}>
            <Typography variant="h7">Innersource</Typography>
          </div>
        </div>
      </Link>
      <div style={{ display: 'flex', flex: '1 1 auto' }} />
      {user && <AppUserMenu fullName={user.name} onCloseSession={handleOnCloseSession} />}
    </AppHeader>
  );
};

export default PortalHeader;
